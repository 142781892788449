import { ACTION_TYPES } from "../actions/main";

const defaultState = {
  loadingResults: false,
  showResults: false,
  links: [],
  results: [],
};

/*  LinkObject: {
      id: string,
      link: string,
    }
*/

export default function mainReducer(state = defaultState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    default:
      return state;
  }
}

export function getStatus(state) {
  return {
    loadingResults: state.loadingResults,
    showResults: state.showResults,
  };
}

export function getLinks(state) {
  return state.links;
}

export function getResults(state) {
  return state.results;
}
