import { Button, Container, Grid } from "@mui/material";
import { PlayCircle } from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import AddLinksPage from "../components/addLinks";
import ResultsPage from "../components/results";
import { getStatus } from "../reducers/main";

function MainPage() {
  const { showResults } = useSelector(getStatus);

  return showResults ? <ResultsPage /> : <AddLinksPage />;
}

export default MainPage;
