import "./App.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import Header from "./components/Header";
import MainPage from "./main/index";

import store from "./store";
import { Provider as StoreProvider } from "react-redux";

function App() {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Header />
        <MainPage />
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
