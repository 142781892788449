import { Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import LinksListItem from "./LinksListItem";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getLinks } from "../../reducers/main";
import { addLink } from "../../actions/main";

function LinksList() {
  const links = useSelector(getLinks);
  const dispatch = useDispatch();
  return (
    <Container>
      <Typography variant="h1" component="div">
        Links To Job Postings
      </Typography>

      <Stack spacing={2} sx={{ marginY: "16px" }}>
        {links.map((link) => {
          return <LinksListItem link={link} key={link.id} />;
        })}
      </Stack>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch(addLink());
        }}
      >
        Add Another Link
        <Add />
      </Button>
    </Container>
  );
}

export default LinksList;
