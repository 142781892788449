import { Button, Container, Grid } from "@mui/material";
import { PlayCircle } from "@mui/icons-material";
import React from "react";

import LinksList from "./LinksList";
import { useSelector } from "react-redux";
import { getStatus } from "../../reducers/main";

function AddLinksPage() {
  const { loadingResults } = useSelector(getStatus);
  return (
    <Grid container mt={2}>
      <Grid item xs={12} md={2}>
        <Container>
          <Button
            variant="contained"
            color="secondary"
            disabled={loadingResults}
          >
            Check Links
            <PlayCircle />
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12} md={10}>
        <LinksList />
      </Grid>
    </Grid>
  );
}

export default AddLinksPage;
