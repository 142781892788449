import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getResults } from "../../reducers/main";
import ResultListItem from "./ResultListItem";

function ResultList() {
  const results = useSelector(getResults);
  return (
    <Container>
      <Typography variant="h1" component="div">
        Results for Job Postings
      </Typography>
      <Stack>
        {results.map((result) => {
          return <ResultListItem result={result} />;
        })}
        <ResultListItem />
      </Stack>
    </Container>
  );
}

export default ResultList;
