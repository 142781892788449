import { IconButton, Stack, TextField } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { editLink, deleteLink } from "../../actions/main";
import { Delete } from "@mui/icons-material";

function LinksListItem(props) {
  const { link } = props;
  const dispatch = useDispatch();
  function handleTextFieldChange(event) {
    dispatch(editLink(link.id, event.target.value));
  }

  function handleDeleteLink() {
    dispatch(deleteLink(link.id));
  }

  return (
    <Stack direction="row">
      <TextField
        sx={{ flex: 1 }}
        defaultValue={link.link}
        onChange={handleTextFieldChange}
      />
      <IconButton
        variant="outlined"
        onClick={handleDeleteLink}
        color="primary"
        aria-label="Like Photo"
      >
        <Delete />
      </IconButton>
    </Stack>
  );
}

export default LinksListItem;
