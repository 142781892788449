import { v4 as uuid_v4 } from "uuid";

export const ACTION_TYPES = {
  SET_LINKS: "SET_LINKS",
};

export function setLinksAction(links) {
  return {
    type: ACTION_TYPES.SET_LINKS,
    payload: links,
  };
}
export function addLink() {
  return (dispatch, getState) => {
    const links = getState().links;
    dispatch(
      setLinksAction([
        ...links,
        {
          id: uuid_v4(),
          link: "",
        },
      ])
    );
  };
}

export function editLink(id, newValue) {
  return (dispatch, getState) => {
    const links = getState().links;
    dispatch(
      setLinksAction(
        links.map((link) => {
          return {
            ...link,
            link: id === link.id ? newValue : link.link,
          };
        })
      )
    );
  };
}

export function deleteLink(id) {
  return (dispatch, getState) => {
    const links = getState().links;
    dispatch(setLinksAction(links.filter((link) => link.id !== id)));
  };
}
