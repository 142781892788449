import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#424242",
      light: "#6d6d6d",
      dark: "#1b1b1b",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#8d6e63",
      light: "#be9c91",
      dark: "#5f4339",
      contrastText: "#ffffff",
    },
  },
  typography: {
    h1: {
      fontSize: "1.8rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.0rem",
    },
  },
  spacing: 8,
});
